import { ButtonTypes } from '../constants/buttonTypes'

export interface IButtonTypeStatus {
	default: string
	selected: string
	disabled: string
	ripple: string
}

export const buttonColorsConfig: { [k in ButtonTypes]: IButtonTypeStatus } = {
	[ButtonTypes.PRIMARY]: {
		default: `bg-petrol-blue-900 hover:bg-sky-200 hover:text-petrol-blue-900 focus-active:bg-sky-700 active:bg-sky-400 disabled:bg-petrol-blue-200
        text-white active:text-petrol-blue-900 disabled:text-petrol-blue-400
        innersvg:fill-white innersvg:active:fill-petrol-blue-800 innersvg:hover:fill-petrol-blue-800 innersvg:disabled:fill-petrol-blue-300
        focus-active:border-1 focus-active:border-solid focus-active:border-petrol-blue-700
        border border-1 border-transparent
        focus-active:shadow-white-circle`,
		selected: `bg-petrol-blue-700
        text-white
        border-1 border-solid border-petrol-blue-700
        shadow-white-circle`,
		disabled: `bg-petrol-blue-200
        border border-1 border-transparent
        text-petrol-blue-400
        fill-petrol-blue-400`,
		ripple: 'bg-petrol-blue-300',
	},
	[ButtonTypes.SECONDARY]: {
		default: `hover:bg-sky-200 text-petrol-blue-900 hover:text-petrol-blue-900 active:bg-sky-400 disabled:bg-petrol-blue-200
        active:text-petrol-blue-900 disabled:text-petrol-blue-400
        innersvg:fill-petrol-blue-800 innersvg:active:fill-petrol-blue-900 innersvg:disabled:fill-fill-petrol-blue-200
        focus-active:shadow-white-circle
        border border-1 border-petrol-blue-800`,
		selected: `bg-petrol-blue-600
        innersvg:fill-white
        shadow-white-circle
        border border-1 border-petrol-blue-800`,
		disabled: `bg-petrol-blue-200
        text-petrol-blue-400
        innersvg:fill-petrol-blue-400
        border border-1 border-warm-grey-200`,
		ripple: 'bg-petrol-blue-400',
	},
	[ButtonTypes.TERTIARY]: {
		default: `hover:bg-sky-200 active:bg-sky-400
        text-petrol-blue-900 hover:petrol-blue-900 active:petrol-blue-900 disabled:text-petrol-blue-400
        innersvg:fill-petrol-blue-800 innersvg:hover:fill-petrol-blue-900 innersvg:active:fill-petrol-blue-900 innersvg:disabled:fill-petrol-blue-400
        focus-active:border-1 focus-active:border-double focus-active:border-petrol-blue-600 disabled:border-1 disabled:border-solid disabled:border-petrol-blue-200
        border border-1 border-solid border-petrol-blue-600`,
		selected: `
        text-petrol-blue-900
        innersvg:fill-petrol-blue-600
        border border-1 border-double border-petrol-blue-600`,
		disabled: `
        text-petrol-blue-400
        innersvg:fill-petrol-blue-400
        border border-1 border-solid border-petrol-blue-200`,
		ripple: 'bg-petrol-blue-300',
	},
	[ButtonTypes.DANGER]: {
		default: `bg-error-400 hover:bg-error-300 active:bg-error-600 disabled:bg-petrol-blue-200
        text-error-900 active:text-white disabled:text-petrol-blue-400
        innersvg:fill-error-900 innersvg:active:fill-white innersvg:disabled:fill-petrol-blue-400
        border border-1 border-transparent
        focus-active: shadow-white-circle`,
		selected: `bg-error-600
        text-white
        border border-1 border-transparent
        innersvg:fill-white`,
		disabled: `bg-petrol-blue-200
        text-petrol-blue-400
        border border-1 border-transparent
        innersvg:fill-petrol-blue-400`,
		ripple: 'bg-error-200',
	},
	[ButtonTypes.GHOST]: {
		default: `hover:bg-petrol-blue-300/40 active:bg-petrol-blue-300/40 
        active:text-petrol-blue-600 disabled:text-petrol-blue-300
        border border-1 border-transparent focus-active:border-petrol-blue-600
        innersvg:focus-active:fill-petrol-blue-600 innersvg:disabled:fill-petrol-blue-300`,
		selected: `bg-petrol-blue-300/40
        text-petrol-blue-600
        border border-1 border-transparent
        innersvg:fill-petrol-blue-600`,
		disabled: `text-petrol-blue-300
        innersvg:fill-petrol-blue-300
        border border-1 border-transparent`,
		ripple: 'bg-petrol-blue-200',
	},
}

export const getButtonColorConfig = (key: ButtonTypes) => {
	return buttonColorsConfig[key] as IButtonTypeStatus
}
