import { RequestType } from '../../../../common/RequestTypes';
import { RestNetworkObject } from '../../../../common/RestNetworkObject';
import { GetAccommodationMarketIntelligenceDataRequest } from './GetAccommodationMarketIntelligenceDataRequest';

export class GetAccommodationMarketIntelligenceDataNetworkObject extends RestNetworkObject<undefined> {
  constructor(params: GetAccommodationMarketIntelligenceDataRequest) {
    super(
      undefined,
      `/accommodations/${params.id}/market-intelligence`,
      RequestType.GET
    );
  }
}
