import type { ColorConfig } from '@/utils/utilTailwind'
import Chart from '@/components/common/chart/Chart.vue'

export enum ChartType {
	Line = 'LINE',
	RangeArea = 'RANGE_AREA',
	Custom = 'CUSTOM',
}

export type LineChartDataPoint = {
	time: Date | string
	value: number | null
}
export type RangeAreaChartDataPoint = {
	time: Date
	min: number
	max: number
}
export type MarkAreaChartDataPoint = {
	from: Date
	to: Date
}

export type ChartSerie = (LineChartDataPoint | RangeAreaChartDataPoint)[]

export type LineChartConfig = {
	type: ChartType.Line
	data: LineChartDataPoint[]
}
export type RangeAreaChartConfig = {
	type: ChartType.RangeArea
	data: RangeAreaChartDataPoint[]
}

export type ChartData = {
	id?: string
	label: string
	colorConfig: {
		serie: ColorConfig
		legend: ColorConfig
		tooltip?: ColorConfig
	}
	defaultVisible?: boolean
} & (LineChartConfig | RangeAreaChartConfig)

export type ChartMarkAreaData = {
	id?: string
	colorConfig: {
		markArea: string
	}
	visible?: boolean
	data: MarkAreaChartDataPoint
}

export type ChartOptions = NonNullable<InstanceType<typeof Chart>['$props']['configuration']>

export type ChartXAxisOptions = Pick<ChartOptions, 'showXAxis' | 'xAxisType' | 'xSplitNumber'>

export type ChartYAxisOptions = Pick<ChartOptions, 'showYAxis' | 'yAxisType' | 'ySplitNumber' | 'currency'>
