import { defineStore, storeToRefs } from 'pinia'
import { useAccommodationsStore } from './accommodations'
import { SubscriptionStatusType } from '../submodules/sharedTypes/common/Billing'

export const useCoreStore = defineStore('❤️ core', () => {
	const accommodationsStore = useAccommodationsStore()
	const { accommodations } = storeToRefs(useAccommodationsStore())
	const { subscriptions, isAccountingUser } = storeToRefs(useUserStore())

	const isSmartpricingEnabled = computed(
		() =>
			!utilBadPayment.isBadSmartpricingPayment(subscriptions.value?.paymentStatus) &&
			accommodationsStore.accommodations.some(
				(accommodation) => accommodation.purchases.smartpricing.permissions.access
			)
	)
	const isSmartpricingFreeEnabled = computed(() =>
		accommodationsStore.accommodations.some(
			(accommodation) => accommodation.purchases.smartpricingFree.permissions.access
		)
	)
	const isMarketIntelligenceEnabled = computed(
		() =>
			isSmartpricingEnabled.value &&
			accommodationsStore.accommodations.some(
				(accommodation) => accommodation.purchases.marketIntelligence.permissions.access
			)
	)
	const isSmartpayingEnabled = computed(
		() =>
			!utilBadPayment.isBadSmartpayingPayment(subscriptions.value?.paymentStatus) &&
			accommodationsStore.accommodations.some(
				(accommodation) =>
					accommodation.purchases.smartpaying != undefined && accommodation.purchases.smartpaying.startsWith('live')
			)
	)
	const isSmartpayingFreeEnabled = computed(() =>
		accommodationsStore.accommodations.some(
			(accommodation) =>
				accommodation.purchases.smartupselling.status === SubscriptionStatusType.Unknown &&
				accommodation.purchases.smartpaying != undefined &&
				accommodation.purchases.smartpaying.startsWith('live')
		)
	)
	const isSmartupsellingEnabled = computed(
		() =>
			!utilBadPayment.isBadSmartpayingPayment(subscriptions.value?.paymentStatus) &&
			accommodationsStore.accommodations.some(
				(accommodation) => accommodation.purchases.smartupselling.permissions.dashboardAccess
			)
	)
	const isSmartConnectCrmEnabled = computed(
		() =>
			!utilBadPayment.isBadSmartpayingPayment(subscriptions.value?.paymentStatus) &&
			accommodationsStore.accommodations.some(
				(accommodation) =>
					accommodation.purchases.smartpaying != undefined &&
					(accommodation.purchases.smartpaying.startsWith('live/crm') ||
						(accommodation.purchases.smartpaying.startsWith('maintenance/crm') && isAccountingUser.value))
			)
	)
	const hasSomeAccommoodationInLive = computed(() =>
		accommodationsStore.accommodations.some((accommodation) =>
			accommodation.purchases.smartpricing.status.startsWith('live')
		)
	)
	const hasOnlyAccommodationsInPreOnboardingStatus = computed(
		() =>
			accommodationsStore.accommodations.length > 0 &&
			accommodationsStore.accommodations.every((accommotion) =>
				accommotion.purchases.smartpricing.status.startsWith('preonboarding')
			)
	)
	const hasSmartpricingAccommodationsInBadPayment = computed(() =>
		accommodationsStore.accommodations.some(
			(accommodation) => accommodation.purchases.smartpricing.status === 'live/notpaying'
		)
	)
	const hasSmartpayingAccommodationsInBadPayment = computed(() =>
		accommodationsStore.accommodations.some((accommodation) => accommodation.purchases.smartpaying === 'live/notpaying')
	)
	const hasAccommodationsInBadPayment = computed(
		() => hasSmartpricingAccommodationsInBadPayment.value || hasSmartpayingAccommodationsInBadPayment.value
	)
	const hasAllAccommodationsInLost = computed(
		() =>
			accommodationsStore.accommodations.length > 0 &&
			accommodationsStore.accommodations.every(
				(accommodation) => accommodation.purchases.smartpricing.status === 'lost/default'
			)
	)
	const canStartAutoonboardingFromExistingAccommodation = computed(() => {
		return (
			(isSmartpricingFreeEnabled.value && !isSmartpricingEnabled.value) ||
			(accommodationsStore.accommodations.length &&
				accommodationsStore.accommodations.every((accommotion) =>
					accommotion.purchases.smartpricing.status.startsWith('preonboarding')
				))
		)
	})

	const hasOnlyAccommodationInOnboardingStatus = computed(
		() =>
			!accommodations.value.length ||
			accommodations.value.every(
				(accommodation) =>
					accommodation.purchases.smartpricing.status === 'onboarding/default' ||
					accommodation.purchases.smartpricing.status === 'created/default'
			)
	)

	const canShowMarketIntelligenceStrategyPage = computed(
		() => !isSmartpricingEnabled.value && isMarketIntelligenceEnabled.value
	)
	const hasOnlySmartpayingEnabled = computed(
		() =>
			(isSmartpayingFreeEnabled || isSmartpayingEnabled.value) &&
			!isSmartpricingEnabled.value &&
			!isMarketIntelligenceEnabled.value &&
			!isSmartupsellingEnabled.value &&
			!isSmartConnectCrmEnabled
	)
	const hasOnlyMarketIntelligenceEnabled = computed(
		() =>
			isMarketIntelligenceEnabled.value &&
			!isSmartpricingEnabled.value &&
			!isSmartupsellingEnabled.value &&
			!isSmartpayingEnabled.value &&
			!isSmartConnectCrmEnabled
	)
	const hasOnlySmartupsellingEnabled = computed(
		() =>
			isSmartupsellingEnabled.value &&
			!isSmartpricingEnabled.value &&
			!isMarketIntelligenceEnabled.value &&
			!isSmartpayingEnabled.value &&
			!isSmartConnectCrmEnabled
	)

	const $reset = () => {}

	return {
		// state
		isSmartpricingEnabled,
		isSmartpricingFreeEnabled,
		isSmartpayingEnabled,
		isSmartpayingFreeEnabled,
		isMarketIntelligenceEnabled,
		isSmartupsellingEnabled,
		isSmartConnectCrmEnabled,

		// actions
		$reset,

		// getters
		canStartAutoonboardingFromExistingAccommodation,
		hasOnlyAccommodationsInPreOnboardingStatus,
		hasOnlyAccommodationInOnboardingStatus,

		hasSomeAccommoodationInLive,
		hasSmartpricingAccommodationsInBadPayment,
		hasSmartpayingAccommodationsInBadPayment,
		hasAccommodationsInBadPayment,
		hasAllAccommodationsInLost,

		canShowMarketIntelligenceStrategyPage,
		hasOnlySmartpayingEnabled,
		hasOnlyMarketIntelligenceEnabled,
		hasOnlySmartupsellingEnabled,
	}
})
