import { SpSvg } from '~/autogen/SpSvg'
import { ModifierType } from './../submodules/sharedTypes/common/Modifiers'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'

export type modifiersDisplayData = {
	text: TranslationKey
	icon: SpSvg
	backgroundColor: string
	textColor: string
	iconColor: string
	borderColor: string
	editorHoverBorder: string
	editorFocusBorder: string
	activeBackground: string
	activeCurrencyIconColor: string
}

export const modifiersConfig: { [k in ModifierType]: modifiersDisplayData } = {
	[ModifierType.Frozen]: {
		text: TranslationKeys.MODIFIER_FROZEN,
		icon: SpSvg.CalendarModFreeze,
		backgroundColor: 'bg-warm-grey-200',
		textColor: 'text-petrol-blue-900',
		iconColor: 'fill-petrol-blue-900',
		borderColor: 'border-warm-grey-400',
		editorHoverBorder: 'hover:border-warm-grey-300',
		editorFocusBorder:
			'focus-within:!border-warm-grey-400 focus-within:outline focus-within:outline-1 focus-within:outline-warm-grey-400',
		activeBackground: 'bg-warm-grey-100',
		activeCurrencyIconColor: 'text-warm-grey-500',
	},
	[ModifierType.Min]: {
		text: TranslationKeys.MODIFIER_MIN,
		icon: SpSvg.CalendarModMin,
		backgroundColor: 'bg-warm-grey-200',
		textColor: 'text-petrol-blue-900',
		iconColor: 'fill-petrol-blue-900',
		borderColor: 'border-warm-grey-300',
		editorHoverBorder: 'hover:border-warm-grey-300',
		editorFocusBorder:
			'focus-within:!border-warm-grey-400 focus-within:outline focus-within:outline-1 focus-within:outline-warm-grey-400',
		activeBackground: 'bg-warm-grey-100',
		activeCurrencyIconColor: 'text-warm-grey-700',
	},
	[ModifierType.Max]: {
		text: TranslationKeys.MODIFIER_MAX,
		icon: SpSvg.CalendarModMax,
		backgroundColor: 'bg-warm-grey-200',
		textColor: 'text-petrol-blue-900',
		iconColor: 'fill-petrol-blue-900',
		borderColor: 'border-warm-grey-300',
		editorHoverBorder: 'hover:border-warm-grey-300',
		editorFocusBorder:
			'focus-within:!border-warm-grey-400 focus-within:outline focus-within:outline-1 focus-within:outline-warm-grey-400',
		activeBackground: 'bg-warm-grey-100',
		activeCurrencyIconColor: 'text-warm-grey-700',
	},
	[ModifierType.Tweak]: {
		text: TranslationKeys.MODIFIER_TWEAK,
		icon: SpSvg.CalendarModMarkup,
		backgroundColor: 'bg-warm-grey-200',
		textColor: 'text-petrol-blue-900',
		iconColor: 'fill-petrol-blue-900',
		borderColor: 'border-warm-grey-300',
		editorHoverBorder: 'hover:border-warm-grey-300',
		editorFocusBorder:
			'focus-within:!border-warm-grey-400 focus-within:outline focus-within:outline-1 focus-within:outline-warm-grey-400',
		activeBackground: 'bg-warm-grey-100',
		activeCurrencyIconColor: '',
	},
}
