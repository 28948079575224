<script setup lang="ts"></script>

<template>
	<div class="h-dvh">
		<div class="relative h-full w-full overflow-hidden bg-petrol-blue-800">
			<TransitionGroup name="bubbles">
				<svg
					class="translate-top-left absolute left-[-700px] top-[-400px]"
					width="1432"
					height="880"
					viewBox="0 0 1432 880"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="m939.05,11.2188c-4.916,-9.6367 -14.646,-9.8849 -23.236,-6.4355l-0.004,0.002l-900.6846,358.2097l-0.0046,0.002c-9.576,3.772 -14.4091,14.337 -11.2416,24l105.1988,318.883c2.278,6.836 8.107,11.805 15.187,12.998c0.001,0 0.002,0 0.003,0.001l976.272,159.7l0.01,0.001c7.06,1.189 14.18,-1.711 18.52,-7.368c0,-0.002 0,-0.003 0,-0.005l308.32,-407.918c0,-0.001 0,-0.003 0,-0.004c6.42,-8.628 4.72,-20.701 -3.89,-27.116m-485.062,-425.7999l60.983,108.1479l0.004,0.007c1.563,2.708 3.535,5.027 6.095,6.821c0.01,0.005 0.01,0.009 0.02,0.013l417.96,310.81"
						stroke="#E4FF00"
						stroke-width="3.10657"
					/>
				</svg>
				<svg
					class="translate-bottom-right absolute bottom-[-400px] right-[-200px]"
					width="1432"
					height="880"
					viewBox="0 0 1432 880"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="m939.05,11.2188c-4.916,-9.6367 -14.646,-9.8849 -23.236,-6.4355l-0.004,0.002l-900.6846,358.2097l-0.0046,0.002c-9.576,3.772 -14.4091,14.337 -11.2416,24l105.1988,318.883c2.278,6.836 8.107,11.805 15.187,12.998c0.001,0 0.002,0 0.003,0.001l976.272,159.7l0.01,0.001c7.06,1.189 14.18,-1.711 18.52,-7.368c0,-0.002 0,-0.003 0,-0.005l308.32,-407.918c0,-0.001 0,-0.003 0,-0.004c6.42,-8.628 4.72,-20.701 -3.89,-27.116m-485.062,-425.7999l60.983,108.1479l0.004,0.007c1.563,2.708 3.535,5.027 6.095,6.821c0.01,0.005 0.01,0.009 0.02,0.013l417.96,310.81"
						stroke="#E4FF00"
						stroke-width="3.10657"
					/>
				</svg>
			</TransitionGroup>
			<div class="absolute h-full w-full">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@keyframes moveTopLeft {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-40px, -30px);
	}
}

.translate-top-left {
	animation: moveTopLeft 5s ease-in-out alternate infinite;
	transform-origin: center;
}

@keyframes moveBottomRight {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(40px, 30px);
	}
}

.translate-bottom-right {
	animation: moveBottomRight 5s ease-in-out alternate infinite;
	transform-origin: center;
}
</style>
