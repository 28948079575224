import { RequestType } from '../../../../common/RequestTypes';
import { RestNetworkObject } from '../../../../common/RestNetworkObject';
import { EditAccommodationMarketIntelligenceCompetitorRequest } from './EditAccommodationMarketIntelligenceCompetitorRequest';

export class EditAccommodationMarketIntelligenceCompetitorNetworkObject extends RestNetworkObject<EditAccommodationMarketIntelligenceCompetitorRequest> {
  constructor(params: EditAccommodationMarketIntelligenceCompetitorRequest) {
    super(
      params,
      `/accommodations/${params.id}/market-intelligence/competitors/edit`,
      RequestType.PUT
    );
  }
}
