import revive_payload_client_1va7gL5Rhu from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_MqIh2exg8I from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_n5ZMWOJpGG from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_q3lmgIzQAf from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FSVZ37fqiU from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cOxd7e6s5x from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_yJO6AYPQf9 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_IbwGAYY3xB from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_tv11fxn2gE from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.5_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_6q6svqOkFO from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.5_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import errorHandling_EicKpRvXfW from "/opt/build/repo/src/plugins/errorHandling.ts";
import googleTagManager_LIIzgQRozu from "/opt/build/repo/src/plugins/googleTagManager.ts";
import pinia_cfuRVwzvtd from "/opt/build/repo/src/plugins/pinia.ts";
import sentry_h0fMjCG9AB from "/opt/build/repo/src/plugins/sentry.ts";
import tracking_tMIteM76jV from "/opt/build/repo/src/plugins/tracking.ts";
import v_calendar_iMjdyQNhyd from "/opt/build/repo/src/plugins/v-calendar.ts";
export default [
  revive_payload_client_1va7gL5Rhu,
  unhead_MqIh2exg8I,
  router_n5ZMWOJpGG,
  payload_client_q3lmgIzQAf,
  navigation_repaint_client_FSVZ37fqiU,
  check_outdated_build_client_cOxd7e6s5x,
  chunk_reload_client_yJO6AYPQf9,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IbwGAYY3xB,
  switch_locale_path_ssr_tv11fxn2gE,
  i18n_6q6svqOkFO,
  errorHandling_EicKpRvXfW,
  googleTagManager_LIIzgQRozu,
  pinia_cfuRVwzvtd,
  sentry_h0fMjCG9AB,
  tracking_tMIteM76jV,
  v_calendar_iMjdyQNhyd
]