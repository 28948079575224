<script setup lang="ts">
import { cheatsHelper } from './devMode/cheatsHelper'
import { UtilSales } from './utils/UtilSales'
import { loadLoggedSectionPrereqs, redirectUserAfterLogin } from './utils/loggedSectionPrereqs'
import { isSessionActive } from './utils/utilToken'
import userflow from 'userflow.js/src/userflow'
import { useTesting } from '~/hooks/useTesting'
import { useMaintenanceMode } from '~/hooks/useMaintenanceMode'

const ready = ref(false)

//useTesting()

const { initLocale } = useLocale()
initLocale()

useMaintenanceMode()

onMounted(async () => {
	userflow.init(useRuntimeConfig().public.USERFLOW_TOKEN)

	if (isSessionActive()) {
		await loadLoggedSectionPrereqs()

		await redirectUserAfterLogin(false)
	}

	ready.value = true
})
</script>

<template>
	<div>
		<Head>
			<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
		</Head>
		<NuxtLayout v-if="ready">
			<NuxtPage />
			<DevToolsCheats v-if="cheatsHelper.canShowCheats()" />
			<DevToolsSalesModes v-if="UtilSales.canUseSalesMode()" />
		</NuxtLayout>
		<ScreensLoading v-else />
	</div>
</template>
