export const enum TrackingMessages {
	PAGE_VIEW = 'Page view',

	// Login
	SIGN_IN = 'Sign in',
	PASSWORD_RESET_REQUESTED = 'Password reset requested',
	PASSWORD_RESET_SUCCESS = 'Password reset',
	PASSWORD_RESET_EMAIL_CLICKED = 'Password reset email clicked',

	// Customer profile & AutoOnboarding
	CUSTOMER_PROFILE_END = 'Customer profile end',
	CUSTOMER_PROFILE_BACK = 'Customer profile back',
	AUTO_ONBOARDING_PARTNER_START = 'Connect start',
	AUTO_ONBOARDING_PARTNER_END = 'Connect end',

	// Calendar
	CALENDAR_ACCOMMODATIONS_FILTERED = 'Calendar accommodations filtered',
	QUOTE_GET = 'Quote get',
	PRICE_COPY = 'Price copy',
	BULK_PRICE_CUSTOMIZATION = 'Bulk price customization',
	SINGLE_PRICE_CUSTOMIZATION = 'Single price customization',
	CALENDAR_NAVIGATION = 'Calendar navigation',
	PRICE_DETAIL_SHEET_OPEN = 'Price detail sheet open',
	PRICE_LIST_ACCOMMODATION_SELECT = 'Price list accommodation select',
	PRICE_PERIODS_VIEW = 'Price periods view',
	PRICE_DEATIL_SHOW_MORE = 'Price detail show more',
	PRICE_DETAIL_CHART_TAB_OPEN = 'Price detail chart tab open',
	MARKET_OCCUPANCY_SWITCH_INTERACT = 'Market occupancy switch interact',

	EVENT_OPEN = 'Event open',
	EVENT_CLOSE = 'Event close',
	EVENT_SETTINGS_VIEW = 'Event settings view',
	EVENT_IMPACT_VIEW = 'Event impact view',
	EVENT_IMPACT_CONFIRM = 'Event impact confirm',
	EVENT_IMPACT_FIELD_CHANGE = 'Event impact field change',
	EVENT_DISABLE = 'Event disable',
	EVENT_RESTORE = 'Event restore',

	KNOWLEDGE_BASE_OPEN = 'Knowledge base open',

	ACADEMY_OPEN = 'Academy open',

	MAKE_A_WISH_DIALOG_OPEN = 'Make a wish dialog opened',
	MAKE_A_WISH_FORM_VIEW = 'Make a wish form viewed',
	MAKE_A_WISH_SUBMIT = 'Wish sent',

	// Charts
	CHARTS_NAVIGATION = 'Charts navigation',
	CHARTS_TYPE_SWITCH = 'Charts type switch',
	CHARTS_DATA_DOWNLOAD = 'Charts data download',
	CHARTS_GRAPH_HOVERED = 'Chart page graph hovered',

	// Dashboard
	DASHBOARD_KPIS_COMPARED = 'Dashboard KPIs compared',
	DASHBOARD_KPIS_ITEM_HOVER = 'Compared item hovered',
	DASHBOARD_VIEW_INTERACT = 'Dashboard view interact',
	DASHBOARD_METRIC_SELECTED = 'Dashboard metric selected',
	DASHBOARD_CHART_HOVERED = 'Dashboard chart hovered',

	// Strategy
	STRATEGY_ACCOMMODATION_SELECT = 'Strategy accommodation select',
	STRATEGY_ROOM_SELECT = 'Strategy room select',
	STRATEGY_CHANGE = 'Strategy change',
	STRATEGY_CONFIRM = 'Strategy confirm',
	STRATEGY_ADVANCE_MODE_OPENED = 'Strategy advance mode opened',
	STRATEGY_SWITCHED_BACK_TO_BASIC_MODE = 'Strategy switched back to basic mode',
	STRATEGY_MARKET_CORRECTION_COMPSET_EDITED = 'Market correction compset edited',

	IN_APP_VIDEO_DIALOG_OPENED = 'In-app video dialog opened',
	IN_APP_VIDEO_OPENED = 'In-app video opened',

	// Market Intelligence
	COMPETITOR_SEARCH = 'Competitor search',
	COMPETITOR_ADD = 'Competitor add',
	COMPETITOR_SELECT = 'Competitor select',
	COMPETITOR_MAP_ZOOM = 'Competitor map zoom',
	COMPETITOR_CHART_INTERACT = 'Competitor chart interact',

	MARKET_INTELLIGENCE_SELECT_ACCOMMODATION = 'Select accommodation',
	STRATEGY_COMPETITOR_ADDED = 'Strategy competitor added',
	STRATEGY_COMPETITOR_REMOVED = 'Strategy competitor removed',
	STRATEGY_COMPETITOR_LOCKED = 'Strategy competitor locked',
	STRATEGY_COMPETITOR_DELETED = 'Strategy competitor deleted',
	MARKET_RESTORED = 'Strategy Market restored',
	MARKET_SAVED = 'Strategy Market saved',

	// Settings
	SETTINGS_SECTION_VIEW = 'Settings section view',
	SETTINGS_ROOM_TYPE_DRAG_END = 'Room type dragged',
	SETTINGS_ROOM_TYPE_ORDER_CHANGED = 'Room type order changed',
	SETTINGS_MIN_STAY_SAVE = 'Minimum stay save',

	// Settings - Minimum stay
	MIN_STAY_SETTING_CHANGED = 'Min stay setting changed',
	DYNAMIC_MIN_STAY_SETTING_CHANGED = 'Dynamic min stay setting changed',
	GAP_FILLING_SETTING_CHANGED = 'Gap filling setting changed',

	// Settings - Starting prices
	PRICE_PERIOD_NEW = 'Price period new',
	PRICE_PERIOD_SAVE = 'Price period save',
	PRICE_LIST_SAVE = 'Price list save',
	PRICE_LIST_TEMPLATE_PAGE_VIEW = 'Price list template page view',
	PRICE_LIST_TEMPLATE_DOWNLOAD = 'Price list template download',
	PRICE_LIST_UPLOAD = 'Price list upload',
	BASE_PRICE_COPY = 'Starting price periods copy',

	// User Settings
	// User Settings - Billing
	BILLING_INFO_CHANGE = 'Billing info change',
	SUBSCRIPTION_OPEN = 'Subscription open',
	INVOICE_DOWNLOAD = 'Invoice download',

	// Other
	HELP_PANEL_OPEN = 'Help panel open',
	PROPERTY_SEARCH = 'Property search',
	PROPERTY_SELECT = 'Property select',
	ROOM_TYPE_SEARCH = 'Room type search',
	PERFORMANCE_NOTIFICATIONS_TOGGLE = 'Performance notification eval',
	ROOM_CONNECTION_START = 'Room connection start',
	ROOMS_CONNECTION_ENDS = 'Room connection ends',
	PRODUCT_UPDATE_MONTH_SELECT = 'Product update month select',
	PRODUCT_UPDATE_ENTRY_INTERACT = 'Product update entry interact',

	FEATURE_FEEDBACK_SHOW = 'Feature feedback show',
	FEATURE_FEEDBACK_GET = 'Feature feedback get',
	FEATURE_SCORE_GET = 'Feature score get',

	HOW_DOES_IT_WORK = 'How does it work',
}
