import { NumericVariationType } from './NumericVariationType';

export type Partner = {
  id: string;
  name: string;
  credentialsType: 'oauth' | 'form';
  supportsMinStays: boolean;
  supportsGapFilling: boolean;
  priceCommuncationMehtod: 'push' | 'pull';
  accessInfoSchema: PartnerAccesInfoParam[];
};

export type PartnerAccesInfoParam = {
  required: boolean;
  key: string;
  label: string;
} & (
  | {
      type: 'string' | 'password' | 'base64_basic_auth';
      defaultValue: string;
    }
  | { type: 'number'; defaultValue: number }
);

export type VirtualRoomSettings = {
  parentRoomTypeId: string;
  numUnits?: number;
  notes?: string;
};

export type RoomTypeMappingRate = {
  id: string;
  name: string;
  pax?: number;
  boardType?: string;
} & (
  | { isMaster: true }
  | {
      isMaster: false;
      variation?: {
        variationType: NumericVariationType;
        variationValue: number; // if variationType is 'percentage' this value should be between 0 and 1
      };
    }
);

export type RoomTypeMapping = {
  id: string;
  name: string;
  type?: string;
  numUnits?: number;
  minOccupancy?: number;
  maxOccupancy?: number;
  stdOccupancy?: number;
  rates: RoomTypeMappingRate[];
} & (
  | { isVirtual: false }
  | { isVirtual: true; virtualRoomSettings: VirtualRoomSettings }
);

export enum SelfOnboardingStep {
  CreateAccommodation = 'create_accommodation',
  PartnerOauthURL = 'partner_oauth_url',
  PartnerIdSelection = 'partner_id_selection',
  GetPartnerId = 'get_partner_id',
  SetPartnerId = 'set_partner_id',
  AccommodationCreated = 'accommodation_created',
  PartnerConnection = 'partner_connection',
  GetRoomTypesMapping = 'get_room_types_mapping',
  SetRoomTypesMapping = 'set_room_types_mapping',
  RoomTypesMapped = 'room_types_mapped',
  DownloadReservations = 'download_reservations',
  ReservationsDownloaded = 'reservations_downloaded',
  Done = 'done',
}

export enum OnboardingStepStatus {
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}
