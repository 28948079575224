const serieColorConfig = getManyColorConfigWithContrast(ComponentColorConfig.ChartSerie, [
	{ colors: ['yellow'], weights: [400] },
	{ colors: ['dark-red'], weights: [300] },
	{ colors: ['red'], weights: [600, 200] },
	{ colors: ['light-blue'], weights: [500] },
	{ colors: ['dark-red'], weights: [300] },
	{ colors: ['sky'], weights: [600] },
])
const tooltipColorConfig = getManyColorConfigWithContrast(ComponentColorConfig.ChartSerieTooltip, [
	{ colors: ['yellow'], weights: [200] },
	{ colors: ['dark-red'], weights: [100, 50] },
	{ colors: ['red'], weights: [100, 50] },
	{ colors: ['light-blue'], weights: [100, 50] },
	{ colors: ['sky'], weights: [100] },
])

const unauthorizedRoutes = ['/auth/signup', '/auth/login', '/auth/reset-password']
const restrictedPhoneNumberRoutes = ['/suggested-prices', '/smart-analysis']
const whitelistRoutes = ['/settings/language']

const smartpricingOnlyRoutes = [
	'/calendar',
	'/dashboard',
	'/chart',
	'/strategies',
	'/competitors',
	'/settings/accommodation',
]
const smartpayingOnlyRoutes = ['/payments', '/marketplace', '/automations', '/reservation', '/crm']

export const Core = {
	unauthenticatedRoutes: unauthorizedRoutes,
	restrictedPhoneNumberRoutes: restrictedPhoneNumberRoutes,

	smartpricingOnlyRoutes: smartpricingOnlyRoutes,
	smartpayingOnlyRoutes: smartpayingOnlyRoutes,
	whitelistRoutes: whitelistRoutes,

	authentication: {
		isGoogleEnabled: false,
		minPasswordLength: 8,
	},
	strategy: { aggressivenessThreshold: { from: -4, to: 5 } },
	colors: {
		charts: {
			line: {
				accommodation: {
					serie: serieColorConfig[6],
					legend: serieColorConfig[6],
					tooltip: tooltipColorConfig[7],
				},
				smartpricing: {
					serie: serieColorConfig[1],
					legend: serieColorConfig[1],
					tooltip: tooltipColorConfig[1],
				},
				marketTrend: {
					serie: serieColorConfig[1],
					legend: serieColorConfig[1],
					tooltip: tooltipColorConfig[1],
				},
				marketThreshold: {
					serie: '#EBC2D1',
					legend: serieColorConfig[3],
					tooltip: tooltipColorConfig[3],
				},
				minMaxOccupancyTrend: {
					serie: '#EBC2D1',
					legend: serieColorConfig[1],
					tooltip: tooltipColorConfig[1],
				},
				currentAccommodationOccupancy: {
					serie: serieColorConfig[4],
					legend: serieColorConfig[4],
					tooltip: tooltipColorConfig[5],
				},
			},
		},
	},
} as const
