import { defineStore } from 'pinia'
import { utilTracking } from '~/utils/utilTracking'
import { FeatureEvent } from '~~/submodules/feedback/types/FeatureEvent'
import { FeedbackId } from '../constants/FeedbackId'
import { StorageKeys } from '../constants/storageKeys'
import { TrackingMessages } from '../constants/trackingMessages'
import { FeedbackHandler } from './../../submodules/feedback/index'
import { UserFeatureEvent } from './../../submodules/feedback/types/UserFeatureEvent'
import { UserFeedback } from './../../submodules/feedback/types/UserFeedback'
import { IS_DEV_MODE, IS_STAGING, IS_PROD } from './../constants/index'

type ModalsState = {
	activeFeedback?: FeatureEvent
	initiated: Boolean
	handler?: FeedbackHandler
	feedbackSessionId?: string
}

export const useFeedbackStore = defineStore('👍 feedback store', {
	state: (): ModalsState => ({
		activeFeedback: undefined,
		initiated: false,
		handler: undefined,
		feedbackSessionId: undefined,
	}),
	actions: {
		init() {
			const isAccountingToken = useUserStore().isAccountingUser
			const hasLiveAccommodations = useCoreStore().hasSomeAccommoodationInLive
			if (!this.initiated && !IS_DEV_MODE() && !isAccountingToken && hasLiveAccommodations) {
				this.handler = new FeedbackHandler(
					useRuntimeConfig().public.FEEDBACK_PROJECT,
					localStorage.getItem(StorageKeys.SessionToken) || '',
					useRuntimeConfig().public.FEEDBACK_URL,
					this.setActiveFeedback
				)
				this.initiated = true
			}
		},
		stop() {
			if (this.initiated && this.handler) {
				this.handler!.kill()
				delete this.handler
				this.initiated = false
			}
		},
		getFeedbackEvent(feedbackId: FeedbackId): UserFeatureEvent | undefined {
			const events = this.handler?.events
			const feedbackEvent = events?.find((event) => event.event.id === this.feedbacksIdMap[feedbackId])

			return feedbackEvent
		},
		getFeedbackEventId(feedbackId: FeedbackId): number {
			return this.feedbacksIdMap[feedbackId]
		},
		setActiveFeedback(activeFeedback: UserFeatureEvent): void {
			this.activeFeedback = activeFeedback.event
			this.feedbackSessionId = crypto.randomUUID()

			utilTracking.track(TrackingMessages.FEATURE_FEEDBACK_SHOW, {
				feature_name: this.activeFeedback.eventKey,
			})
		},
		giveFeedback(feedback: UserFeedback): void {
			if (this.handler != undefined) {
				const feedbackWithSession = {
					...feedback,
					sessionId: this.feedbackSessionId!,
				}
				this.handler.onTrack(feedbackWithSession)
			}
		},
		rejectFeedback(): void {
			const id = this.activeFeedback?.id
			if (id != undefined && this.handler != undefined) {
				this.handler.onReject(id)
			}
		},
		requestFeedback(eventId: FeedbackId): void {
			const isAccountingToken = useUserStore().isAccountingUser
			const hasLiveAccommodations = useCoreStore().hasSomeAccommoodationInLive
			const feedbackEventId = this.getFeedbackEventId(eventId)
			if (this.handler != undefined && !isAccountingToken && hasLiveAccommodations) {
				this.handler.onManualTrigger(feedbackEventId)
			}
		},
	},
	getters: {
		feedbacksIdMap(): Record<FeedbackId, number> {
			return {
				[FeedbackId.StrategyChange]: 1,
				[FeedbackId.BulkPriceCustomization]: 2,
				[FeedbackId.EventConfirm]: 6,
				[FeedbackId.Add3OrMoreCompetitors]: 7,
				[FeedbackId.CompetitorsMapZoomInOrOut]: 8,
				[FeedbackId.CompetitorsChartInteraction]: 9,
				[FeedbackId.PriceListSave]: 10,
				[FeedbackId.DashboardKPISCompared]: IS_PROD() ? 13 : 11,
				[FeedbackId.ChartsHover]: IS_PROD() ? 52 : 21,
				[FeedbackId.StrategyAdvancedChange]: IS_PROD() ? 53 : 22,
				[FeedbackId.MinStayChange]: IS_PROD() ? 55 : 23,
				[FeedbackId.DropPickupCustomRule]: IS_PROD() ? 54 : 24,
				[FeedbackId.MarketCorrection]: IS_PROD() ? 57 : IS_STAGING() ? 26 : 25,
				[FeedbackId.StrategyCompsetSave]: IS_PROD() ? 58 : IS_STAGING() ? 27 : 26,
			}
		},
	},
})
