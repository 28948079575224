import { RequestType } from '../../../../common/RequestTypes';
import { RestNetworkObject } from '../../../../common/RestNetworkObject';
import { GetAccommodationMarketIntelligenceCompetitorsPricesRequest } from './GetAccommodationMarketIntelligenceCompetitorsPricesRequest';

export class GetAccommodationMarketIntelligenceCompetitorsPricesNetworkObject extends RestNetworkObject<undefined> {
  constructor(
    params: GetAccommodationMarketIntelligenceCompetitorsPricesRequest
  ) {
    super(
      undefined,
      `/accommodations/${params.id}/market-intelligence/competitors/prices`,
      RequestType.GET
    );
  }
}
