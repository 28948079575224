import { RequestType } from '../../../../common/RequestTypes';
import { RestNetworkObject } from '../../../../common/RestNetworkObject';
import { ResetAccommodationMarketIntelligenceCompetitorSetRequest } from './ResetAccommodationMarketIntelligenceCompetitorSetRequest';

export class ResetAccommodationMarketIntelligenceCompetitorSetNetworkObject extends RestNetworkObject<undefined> {
  constructor(
    params: ResetAccommodationMarketIntelligenceCompetitorSetRequest
  ) {
    super(
      undefined,
      `/accommodations/${params.id}/market-intelligence/competitors/reset`,
      RequestType.PUT
    );
  }
}
