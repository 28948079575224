import { WSMessageEvent } from './WebSocket';

export const enum WsServerMessageEvent {
  WSError = WSMessageEvent.WSError,
  WSPricesPredictionPreviewError = WSMessageEvent.WSPricesPredictionPreviewError,
  WSSavePricesPredictionError = WSMessageEvent.WSSavePricesPredictionError,
  WSTokenExpiredErrorOnConnection = WSMessageEvent.WSTokenExpiredErrorOnConnection,
  WSTokenExpiredErrorOnMessage = WSMessageEvent.WSTokenExpiredErrorOnMessage,

  GetAccommodationSoldNightsRange = WSMessageEvent.GetAccommodationSoldNightsRange,
  GetRoomTypeSoldNightsRange = WSMessageEvent.GetRoomTypeSoldNightsRange,

  UpdateRoomTypesOrder = WSMessageEvent.UpdateRoomTypesOrder,

  // prices
  GetAccommodationsPrices = WSMessageEvent.GetAccommodationsPrices,
  PricesUpdate = WSMessageEvent.PricesUpdate,
  GetRoomTypesPricesDetails = WSMessageEvent.GetRoomTypesPricesDetails,
  GetAccommodationsPricesSent = WSMessageEvent.GetAccommodationsPricesSent,

  // occupancies
  GetAccommodationsOccupancies = WSMessageEvent.GetAccommodationsOccupancies,
  GetAccommodationsMarketOccupancy = WSMessageEvent.GetAccommodationsMarketOccupancy,

  // events
  GetAccommodationsEvents = WSMessageEvent.GetAccommodationsEvents,
  GetEventDetails = WSMessageEvent.GetEventDetails,
  GetEventImpactPreview = WSMessageEvent.GetEventImpactPreview,
  CreateEvent = WSMessageEvent.CreateEvent,
  UpdateEvent = WSMessageEvent.UpdateEvent,
  RemoveEvent = WSMessageEvent.RemoveEvent,
  ToggleEventVisibility = WSMessageEvent.ToggleEventVisibility,

  // modifiers
  GetRoomTypesEnabledModifiers = WSMessageEvent.GetRoomTypesEnabledModifiers,
  GetAccommodationGlobalModifiers = WSMessageEvent.GetAccommodationGlobalModifiers,

  // quotes
  CalculateQuotation = WSMessageEvent.CalculateQuotation,

  // date details
  AccommodationDateDetailsStats = WSMessageEvent.AccommodationDateDetailsStats,
  AccommodationDateDetailsBookingOccupancies = WSMessageEvent.AccommodationDateDetailsBookingOccupancies,
  RoomTypeDateDetailsPrice = WSMessageEvent.RoomTypeDateDetailsPrice,
  RoomTypeDateDetailsEvents = WSMessageEvent.RoomTypeDateDetailsEvents,
  RoomTypeDateDetailsStats = WSMessageEvent.RoomTypeDateDetailsStats,
  RoomTypeDateDetailsPriceComputationHistory = WSMessageEvent.RoomTypeDateDetailsPriceComputationHistory,
  RoomTypeDateDetailsBookingEvents = WSMessageEvent.RoomTypeDateDetailsBookingEvents,
  RoomTypeDateDetailsBookingOccupancies = WSMessageEvent.RoomTypeDateDetailsBookingOccupancies,

  // prices prediction
  GetAccommodationStrategy = WSMessageEvent.GetAccommodationStrategy,
  GetAccommodationEnabledTweakModifier = WSMessageEvent.GetAccommodationEnabledTweakModifier,
  UpdateAccommodationStrategy = WSMessageEvent.UpdateAccommodationStrategy,
  RoomTypesPricesPrediction = WSMessageEvent.RoomTypesPricesPrediction,
  GetHowDoesItWorkMarketCorrection = WSMessageEvent.GetHowDoesItWorkMarketCorrection,

  // user settings
  GetUserSettings = WSMessageEvent.GetUserSettings,
  UpdateUserSettings = WSMessageEvent.UpdateUserSettings,

  // user preferences
  GetUserPreferences = WSMessageEvent.GetUserPreferences,
  SetUserPreferences = WSMessageEvent.GetUserPreferences,

  // release notes
  GetReleaseNotesMonthlyGroups = WSMessageEvent.GetReleaseNotesMonthlyGroups,
  GetLatestReleaseNotesVersion = WSMessageEvent.GetLatestReleaseNotesVersion,
  GetLatestReleaseNotes = WSMessageEvent.GetLatestReleaseNotes,
  GetReleaseNotes = WSMessageEvent.GetReleaseNotes,

  // base prices
  GetAccommodationBasePrices = WSMessageEvent.GetAccommodationBasePrices,
  GetAccommodationBasePricesPredictionPreview = WSMessageEvent.GetAccommodationBasePricesPredictionPreview,
  GetAdvancedExpertUserParamsMapping = WSMessageEvent.GetAdvancedExpertUserParamsMapping,
  UpdateAccommodationBasePrices = WSMessageEvent.UpdateAccommodationBasePrices,

  // onboarding
  GetOnboardingProfileLastUpdate = WSMessageEvent.GetOnboardingProfileLastUpdate,
  GetOnboardingProgress = WSMessageEvent.GetOnboardingProgress,

  // billing
  GetBillingProfiles = WSMessageEvent.GetBillingProfiles,
  UpdateBillingInfo = WSMessageEvent.UpdateBillingInfo,
  GetInvoices = WSMessageEvent.GetInvoices,
  GetInvoiceYears = WSMessageEvent.GetInvoiceYears,

  // partners
  GetAvailablePartners = WSMessageEvent.GetAvailablePartners,
  GetPartnerAccessInfoSchema = WSMessageEvent.GetPartnerAccessInfoSchema,

  // minimum stay
  GetOrphanNightsFixedDates = WSMessageEvent.GetOrphanNightsFixedDates,
  GetMinimumStayRules = WSMessageEvent.GetMinimumStayRules,
  UpdateMinimumStayRules = WSMessageEvent.UpdateMinimumStayRules,
}

export type WSServerMessage<T> = {
  event: WsServerMessageEvent;
  data: T;
};
