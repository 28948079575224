import { default as _91_46_46_46slug_93L2lBPLM2g1Meta } from "/opt/build/repo/src/pages/[...slug].vue?macro=true";
import { default as activate5gb3VjPTrWMeta } from "/opt/build/repo/src/pages/auth/activate.vue?macro=true";
import { default as loginkIn80CuK0vMeta } from "/opt/build/repo/src/pages/auth/login.vue?macro=true";
import { default as reset_45passwordCfgS4FpWFaMeta } from "/opt/build/repo/src/pages/auth/reset-password.vue?macro=true";
import { default as indexw9ENX5lolAMeta } from "/opt/build/repo/src/pages/auto-onboarding/index.vue?macro=true";
import { default as registrationyw2bYh58eKMeta } from "/opt/build/repo/src/pages/auto-onboarding/registration.vue?macro=true";
import { default as calendarN3mAK8xcxxMeta } from "/opt/build/repo/src/pages/calendar.vue?macro=true";
import { default as chartyv98bCr6HmMeta } from "/opt/build/repo/src/pages/chart.vue?macro=true";
import { default as competitorsra6TNWnPswMeta } from "/opt/build/repo/src/pages/competitors.vue?macro=true";
import { default as dashboard6jrmk3eHoRMeta } from "/opt/build/repo/src/pages/dashboard.vue?macro=true";
import { default as maintenancenwbhLjzWtmMeta } from "/opt/build/repo/src/pages/maintenance.vue?macro=true";
import { default as onboardingA6hRJW2HZvMeta } from "/opt/build/repo/src/pages/onboarding.vue?macro=true";
import { default as accommodationttHp1y4GRsMeta } from "/opt/build/repo/src/pages/settings/accommodation.vue?macro=true";
import { default as base_45pricesSbxD5A5ItMMeta } from "/opt/build/repo/src/pages/settings/base-prices.vue?macro=true";
import { default as billing6RhVNb1zZFMeta } from "/opt/build/repo/src/pages/settings/billing.vue?macro=true";
import { default as indexsVBQ2G1dkjMeta } from "/opt/build/repo/src/pages/settings/index.vue?macro=true";
import { default as language6JfTgPpXQ1Meta } from "/opt/build/repo/src/pages/settings/language.vue?macro=true";
import { default as notificationsBc229QxV3bMeta } from "/opt/build/repo/src/pages/settings/notifications.vue?macro=true";
import { default as strategies7oqUgqVnf3Meta } from "/opt/build/repo/src/pages/strategies.vue?macro=true";
import { default as component_45stubDRfSSvdl9LMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubDRfSSvdl9L } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.5.0_magicast@0.3.5__okk5iycvensoflql3n3l4jeoni/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-activate",
    path: "/auth/activate",
    meta: activate5gb3VjPTrWMeta || {},
    component: () => import("/opt/build/repo/src/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginkIn80CuK0vMeta || {},
    component: () => import("/opt/build/repo/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordCfgS4FpWFaMeta || {},
    component: () => import("/opt/build/repo/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auto-onboarding",
    path: "/auto-onboarding",
    meta: indexw9ENX5lolAMeta || {},
    component: () => import("/opt/build/repo/src/pages/auto-onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "auto-onboarding-registration",
    path: "/auto-onboarding/registration",
    meta: registrationyw2bYh58eKMeta || {},
    component: () => import("/opt/build/repo/src/pages/auto-onboarding/registration.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/opt/build/repo/src/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "chart",
    path: "/chart",
    component: () => import("/opt/build/repo/src/pages/chart.vue").then(m => m.default || m)
  },
  {
    name: "competitors",
    path: "/competitors",
    component: () => import("/opt/build/repo/src/pages/competitors.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/opt/build/repo/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancenwbhLjzWtmMeta || {},
    component: () => import("/opt/build/repo/src/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingA6hRJW2HZvMeta || {},
    component: () => import("/opt/build/repo/src/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "settings-accommodation",
    path: "/settings/accommodation",
    component: () => import("/opt/build/repo/src/pages/settings/accommodation.vue").then(m => m.default || m)
  },
  {
    name: "settings-base-prices",
    path: "/settings/base-prices",
    component: () => import("/opt/build/repo/src/pages/settings/base-prices.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "/settings/billing",
    component: () => import("/opt/build/repo/src/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/build/repo/src/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-language",
    path: "/settings/language",
    component: () => import("/opt/build/repo/src/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "/settings/notifications",
    component: () => import("/opt/build/repo/src/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "strategies",
    path: "/strategies",
    component: () => import("/opt/build/repo/src/pages/strategies.vue").then(m => m.default || m)
  },
  {
    name: component_45stubDRfSSvdl9LMeta?.name,
    path: "/",
    component: component_45stubDRfSSvdl9L
  }
]