import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../../utils/dates';
import {
  GetAccommodationsMarketOccupancyRequest,
  GetAccommodationsMarketOccupancyRequestPayload,
} from './GetAccommodationsMarketOccupancyRequest';

export class GetAccommodationsMarketOccupancydNetworkObject extends WebSocketNetworkObject<
  GetAccommodationsMarketOccupancyRequest,
  GetAccommodationsMarketOccupancyRequestPayload
> {
  constructor(params: GetAccommodationsMarketOccupancyRequest) {
    super(params, WSMessageEvent.GetAccommodationsMarketOccupancy);
  }

  override getParams(): GetAccommodationsMarketOccupancyRequestPayload {
    const { accommodationIds, dateRange } = this.params;
    return {
      accommodationIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
    };
  }
}
